<template>
  <div class="main">
      <el-container style="height: 100%" direction="vertical">
          <el-header class="header" height="80px">
              <span class="title">泄漏源模型</span>
              <div
                  class="menu-item"
                  @click="changeMenuItemStyle('gb')"
                  :class="{active:activeIndex==='gb'}"
              >
                  国 标
              </div>
              <div
                  class="menu-item"
                  @click="changeMenuItemStyle('ccps')"
                  :class="{active:activeIndex==='ccps'}"
              >
                  C C P S
              </div>
              <button @click="toCsat">返回主页</button>
              <button class="info-btn" @click="openInfo"><InfoFilled style="height: 16px;"/>关于我们</button>
          </el-header>
          <el-main class="main-el">
              <div class="main-board">
                  <router-view></router-view>
              </div>
          </el-main>
          <el-footer style="height: 80px;padding:10px">
            <img style="height: 100%;cursor:pointer" src="@/assets/img/app-logo.png" @click="jump">
            <img style="height: 100%;" src="@/assets/img/app-code.png">
          </el-footer>
          <AboutModal ref="aboutModalRef"/>
      </el-container>
  </div>
</template>

<script setup>
import {InfoFilled} from '@element-plus/icons-vue'
import {useRouter,useRoute} from 'vue-router'
import {watch,ref} from 'vue'
import AboutModal from './components/AboutModal.vue';
const route=useRoute()
const router = useRouter()

const activeIndex=ref('gb')

const toCsat=()=>{
    router.push('/')
}

const changeMenuItemStyle=(path)=>{
  if(activeIndex.value===path)return
  router.push(`/stem/${path}`)
}

watch(()=>route.path,(val)=>{
  if(val.includes('/gb/')){
    activeIndex.value='gb'
  }
  if(val.includes('/ccps/')){
    activeIndex.value='ccps'
  }
},{immediate:true,deep:true})


const jump=()=>{
  window.open('https://china-psc.com/')
}

const aboutModalRef=ref()

const openInfo=()=>{
  aboutModalRef.value.visible=true
}
</script>
<style scoped>
#shiny-shadow {
display: flex;
align-items: center;
justify-content: center;
height: 100vh;
background: #1c2541;
}

button {
border: 2px solid white;
background: transparent;
text-transform: uppercase;
color: white;
padding: 15px 50px;
outline: none;
overflow: hidden;
position: relative;
float: right;
margin-top: 13.75px;
cursor: pointer;
}

span {
z-index: 20;  
}

button:after {
content: '';
  display: block;
  position: absolute;
  top: -36px;
  left: -100px;
  background: white;
  width: 50px;
  height: 125px;
  opacity: 20%;
  transform: rotate(-45deg);
}

.info-btn{
  display: flex;
  align-items: center;
  margin-right: 16px;
}

button:hover:after {
left: 120%;
transition: all 600ms cubic-bezier(0.3, 1, 0.2, 1);
 -webkit-transition: all 600ms cubic-bezier(0.3, 1, 0.2, 1);
}
.menu-item {
  font-size: 16px;
  color: white;
  float: left;
  height: 80px;
  width: 80px;
  text-align: center;
  cursor: pointer;
  transition: all 0.6s;
  font-weight: 300;
}
.menu-item:hover {
  background-color: rgba(255, 255, 255, 0.308);
}
.header {
  background-color: rgb(53, 64, 85);
  color: white;
  line-height: 80px;
}
.title {
  font-size: 24px;
  font-weight: 600;
  float: left;
  margin-right: 40px;
}
.main-el {
  background-color: rgb(230, 233, 241);
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
}
.main-board {
  height: 100%;
  border-radius: 15px;
  padding: 0 10px 0 0;
  overflow-y: hidden;
}
#app,
.main,
.el-container {
  padding: 0;
  margin: 0;
  height: 100%;
}

.active{
  height: 100%;
  font-weight: 600;
  background: rgb(230, 233, 241)!important;
  /* border-bottom: 10px solid white; */
  font-size: 20px;
  color: rgb(53, 64, 85);
}
</style>
