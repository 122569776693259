import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '../layout/index.vue'

const routes = [
  {
    path:'/',
    name:'home',
    component:()=>import('@/views/home')
  },
  {
      path: '/home',
      name: 'home-page',
      component:Layout ,
  }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
